import { Box, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { fetchDocuments } from '../api';
import GameListTable from '../components/GameLibrary/GameListTable';
import AuthContext from '../context/AuthContext';

const Roadmap: React.FC = () => {
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const [sortBy, setSortBy] = useState('');
	const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');

	const { state: authState } = useContext(AuthContext);

	const {
		status: fetchDocumentsStatus,
		data: fetchDocumentsResponse,
		error: documentsFetchError,
		isRefetching: documentsIsRefetching,
	} = useQuery(
		['documents', page, pageSize, {}, sortBy, sortDirection, '', 'roadmap'],
		({ queryKey, signal }) => fetchDocuments(queryKey, authState.token, signal),
		{ enabled: !!authState.token }
	);

	const pageCount = Math.ceil((fetchDocumentsResponse?.count ?? 1) / pageSize);

	useEffect(() => {
		setPage(1);
	}, [pageSize]);

	return (
		<Box>
			<Grid width="100%" paddingTop="94px" height={'100vh'} container>
				<Grid xs={12} lg={12} item>
					<GameListTable
						loading={fetchDocumentsStatus === 'loading' || documentsIsRefetching}
						error={fetchDocumentsStatus === 'error' ? documentsFetchError : undefined}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						pageCount={pageCount}
						sortBy={sortBy}
						setSortBy={setSortBy}
						sortDirection={sortDirection}
						setSortDirection={setSortDirection}
						selectedFilters={{}}
						documents={fetchDocumentsResponse?.data ?? []}
						totalDocs={fetchDocumentsResponse?.count ?? 0}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Roadmap;
