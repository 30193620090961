const path = (root: string, sublink: string) => `${root}${sublink}`;

export const ROOT_PATH = '/';

export const PAGE_PATH = {
	login: '/login',
	gameLibrary: '/game-library',
	masterGameList: '/master-game-list',
	roadmap: '/roadmap',
	page403: '/403',
	page404: '/404',
	page500: '/500',
};

export const DASHBOARD_PATH = {
	root: ROOT_PATH,
	client: {
		root: path(ROOT_PATH, '/client'),
		list: path(ROOT_PATH, '/client/list'),
		details: (id: string) => path(ROOT_PATH, `/client/${id}`),
		edit: (id: string) => path(ROOT_PATH, `/client/${id}/edit`),
		add: path(ROOT_PATH, '/client/add'),
	},
};
