import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, Container, Grid as MuiGrid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { decode } from 'html-entities';
import { useCallback, useState } from 'react';
import SwiperCore, { Grid, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { VendorUpdate } from '../../types';

declare const window: any;

type VendorItemProps = {
	title: string;
	image: string;
	link: string;
	isPromotion?: boolean;
};

const vendorItemClick = (link: string) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'homepage_item_click',
			eventProps: {
				homepage_clicked_section: 'Latest News'
			}
		});
	}
	window.open(link, '_blank')
}

const ImagePlaceHolder: React.FC<{ title: string; isPromotion?: boolean }> = ({ title, isPromotion }) => {
	return (
		<Box
			sx={{
				backgroundColor: 'grey.200',
				height: '150px',
				width: '150px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: '30px',
			}}
		>
			{!isPromotion && (
				<Typography fontSize="small" px="10px" textAlign="center" component="div" fontWeight="bold">
					{title}
				</Typography>
			)}
		</Box>
	);
};

const VendorItem: React.FC<VendorItemProps> = ({ title, image, link, isPromotion }) => {
	const _decodedTitle = decode(title);
	return (
		<Box>
			<MuiGrid px={2} container wrap="nowrap" alignItems="center" height="100%" spacing={2}>
				<MuiGrid item xs={'auto'}>
					{image ? (
						<img
							width="150px"
							height="150px"
							style={{ borderRadius: '30px', marginLeft: -16 }}
							alt={_decodedTitle}
							src={image}
						/>
					) : (
						<ImagePlaceHolder isPromotion={isPromotion} title={_decodedTitle} />
					)}
				</MuiGrid>
				<MuiGrid item>
					<Typography mb={2} variant="subtitle2" fontSize={17}>
						{_decodedTitle}
					</Typography>
					{link.length > 0 && (
						<Button variant="outlined" onClick={() => vendorItemClick(link)} size="small">
							Read more
						</Button>
					)}
				</MuiGrid>
			</MuiGrid>
		</Box>
	);
};

SwiperCore.use([Navigation]);

type Props = {
	vendors?: Array<VendorUpdate>;
	promotions?: Boolean;
};

const VendorsSlider: React.FC<Props> = ({ vendors, promotions }) => {
	const isTablet = useMediaQuery('(max-width:800px)');
	const [swipe, setSwipe] = useState<SwiperCore>();

	const buttonStyles = {
		background: 'white',
		borderRadius: '20%',
		boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
		'&:hover': {
			background: 'rgba(255,255,255,0.8)',
			boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
		},
	};

	const vendorsNavButton = useCallback(() => {
		return [
			(vendors || [])?.length > 2 ? 'block' : 'none', // 0px
			(vendors || [])?.length > 3 ? 'block' : 'none', // 600px
			(vendors || [])?.length > 3 ? 'block' : 'none', // 900px
			(vendors || [])?.length > 4 ? 'block' : 'none', // 1200px
			(vendors || [])?.length > 4 ? 'block' : 'none', // 1536px
		];
	}, [vendors]);

	return (
		<Container sx={{ maxWidth: '1100px !important' }}>
			{vendors && (
				<Box mb={10} height={vendors?.length > 2 ? '400px' : isTablet ? '400px' : 'auto'}>
					<Stack
						sx={{ marginBottom: '30px' }}
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Typography variant="h4">{promotions ? 'Promotions' : 'Latest News'}</Typography>
						<Stack display={vendorsNavButton()} direction="row" spacing={1}>
							<IconButton onClick={() => swipe?.slidePrev()} sx={buttonStyles}>
								<KeyboardArrowLeft />
							</IconButton>
							<IconButton onClick={() => swipe?.slideNext()} sx={buttonStyles}>
								<KeyboardArrowRight />
							</IconButton>
						</Stack>
					</Stack>
					<Swiper
						slidesPerView={isTablet ? 1 : 2}
						onBeforeInit={(swipper) => setSwipe(swipper)}
						style={{
							width: '100%',
							height: '100%',
							marginLeft: 'auto',
							marginRight: 'auto',
						}}
						grid={{ rows: vendors?.length > 2 ? 2 : isTablet ? 2 : 1 }}
						// pagination={{ clickable: true}}
						modules={[Grid]}
					>
						{vendors?.map(({ title, image, link }, id) => (
							<SwiperSlide style={{ height: 'calc((100% - 30px) / 2)' }} key={id}>
								<Box height={'calc((100% - 30px) / 2) !important'}>
									<VendorItem title={title} image={image} link={link} />
								</Box>
							</SwiperSlide>
						))}
					</Swiper>
				</Box>
			)}
			{/* {promotions && (
				<Box mb={10}>
					<Typography sx={{ marginBottom: '40px' }} variant="h4">
						Promotions
					</Typography>
					<MuiGrid container spacing={2}>
						{promotions.map(({ title, image, link }, id) => (
							<MuiGrid key={id} item xs={12} lg={6}>
								<VendorItem title={title} isPromotion image={image} link={link} />
							</MuiGrid>
						))}
					</MuiGrid>
				</Box>
			)} */}
		</Container>
	);
};

export default VendorsSlider;
