import React, { createContext, useEffect, useReducer } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { verifyToken } from '../api';

const initialState = {
	loading: true,
	token: '',
};

type State = typeof initialState;

type Action =
	| {
			type: 'SET_TOKEN';
			payload: string;
	  }
	| {
			type: 'REMOVE_TOKEN';
	  };

const AuthContext = createContext({ state: initialState, dispatch: (action: Action) => {} });

const authReducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'SET_TOKEN':
			return {
				...state,
				token: action.payload,
				loading: false,
			};
		case 'REMOVE_TOKEN':
			return {
				...state,
				token: '',
				loading: false,
			};
		default:
			return state;
	}
};

type Props = {
	children?: React.ReactNode;
};

export const AuthProvider: React.FC<Props> = ({ children }) => {
	const [token] = useLocalStorage('_token', '');
	const [state, dispatch] = useReducer(authReducer, initialState);

	useEffect(() => {
		(async () => {
			try {
				const validToken = await verifyToken(token);
				if (validToken) {
					dispatch({ type: 'SET_TOKEN', payload: token });
				} else {
					dispatch({ type: 'REMOVE_TOKEN' });
				}
			} catch (err) {
				dispatch({ type: 'REMOVE_TOKEN' });
			}
		})();
	}, [token]);

	return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
