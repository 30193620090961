import DownloadIcon from '@mui/icons-material/Download';
import {
	Box,
	Button,
	Grid,
	MenuItem,
	Pagination,
	PaginationItem,
	Select,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {
	DataGrid,
	GridColDef,
	GridRowId,
	GridRowsProp,
	GridSortModel,
	GridValueFormatterParams,
} from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import bytes from 'bytes';
import { decode } from 'html-entities';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import SearchContext from '../../context/SearchContext';
import { Document, FetchDocumentsApiResponse, Filters } from '../../types';
import { formatDate, niceTry } from '../../utils';
import DownloadDialog from '../DownloadDialog';

type TableData = {
	id: number;
	title: string;
	size: number;
	date: Date;
	fileUrl: string;
};

type Props = {
	loading: boolean;
	error: any;
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	pageSize: number;
	setPageSize: React.Dispatch<React.SetStateAction<number>>;
	sortBy: string;
	setSortBy: React.Dispatch<React.SetStateAction<string>>;
	sortDirection: 'ASC' | 'DESC';
	setSortDirection: React.Dispatch<React.SetStateAction<'ASC' | 'DESC'>>;
	selectedFilters: Filters;
	pageCount: number;
	documents: Document[];
	totalDocs: number;
};

const GameListTable: React.FC<Props> = ({
	loading,
	error,
	page,
	setPage,
	pageSize,
	setPageSize,
	sortBy,
	setSortBy,
	sortDirection,
	setSortDirection,
	selectedFilters,
	pageCount,
	documents = [],
	totalDocs = 0,
}) => {
	const { state } = useContext(SearchContext);

	const [selectedIds, setSelectedIds] = useState<Array<GridRowId>>([]);
	const [dialogOpen, setDialogOpen] = useState(false);

	const theme = useTheme();
	const xl = useMediaQuery(theme.breakpoints.up('xl'));

	const getFileIcon = (fileUrl: string) => {
		if (!fileUrl) return 'default-file';

		const iconType = fileUrl?.split('.').pop() || '';

		if (iconType === 'pdf') return 'pdf-file';
		if (['7zip', '7z', 'gzip', 'zip', 'rar'].includes(iconType)) return 'zip-file';
		if (['csv', 'xls', 'xlsx'].includes(iconType)) return 'xls-file';

		return 'default-file';
	};

	const COLUMNS: GridColDef[] = useMemo(
		() => [
			{
				minWidth: 50,
				flex: 0.1,
				field: 'pdf',
				align: 'center',
				sortable: false,
				headerAlign: 'left',
				headerName: '',
				renderCell: (params) => {
					const { fileUrl } = params.row as TableData;
					return (
						<img
							src={`${process.env.PUBLIC_URL}/images/${getFileIcon(fileUrl)}.svg`}
							height="30px"
							alt="PDF icon"
						/>
					);
				},
			},
			{
				field: 'title',
				minWidth: 300,
				flex: 1,
				headerName: 'Title',
				valueFormatter: ({ value }: GridValueFormatterParams<string>) => decode(value),
			},
			{
				field: 'size',
				flex: 0.2,
				headerName: 'Size',
				minWidth: 70,
				sortable: false,
				valueFormatter: ({ value }: GridValueFormatterParams<number | boolean>) => {
					if (typeof value === 'number') {
						return bytes.format(value, {
							unitSeparator: ' ',
							thousandsSeparator: ',',
							unit: 'MB',
							decimalPlaces: 2,
						});
					} else return '-';
				},
				width: 100,
			},
			{
				field: 'date',
				minWidth: 100,
				flex: 0.2,
				headerName: 'Date',
				valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDate(value),
				width: 150,
			},
			{
				field: 'download',
				minWidth: 200,
				flex: 0.3,
				align: 'right',
				headerName: '',
				sortable: false,
				headerAlign: 'right',
				renderHeader: () => (
					<Button
						variant="outlined"
						onClick={() => setDialogOpen(true)}
						sx={{ marginLeft: 'auto', borderColor: "#61CE75", color: "#61CE75" }}
						size="small"
					>
						{xl ? 'Download' : <DownloadIcon />}
						{selectedIds.length ? ' Selected' : ' All'}
					</Button>
				),
				renderCell: (params) => {
					const { fileUrl } = params.row as TableData;
					const url = niceTry(() => new URL(fileUrl));

					const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
						e.stopPropagation();
						if (url) {
							setSelectedIds([params.id]);
							setTimeout(() => setDialogOpen(true), 100);
						}
					};

					return (
						<Button variant="outlined" sx={{ borderColor: "#61CE75", color: "#61CE75" }} size="small" onClick={onClick} disabled={!url}>
							{xl ? 'Download' : <DownloadIcon />}
						</Button>
					);
				},
			},
		],
		[selectedIds, xl]
	);

	useEffect(() => {
		setSelectedIds([]);
	}, [state.searchQuery, selectedFilters]);

	const [tableData, setTableData] = useState<GridRowsProp<TableData>>([]);

	useEffect(() => {
		let _tableData: GridRowsProp<TableData> = [];

		_tableData = documents.map((doc) => ({
			id: doc.documentId,
			title: doc.documentName,
			size: doc.documentSize,
			date: new Date(doc.documentDate * 1000),
			fileUrl: doc.fileUrl,
		}));

		setTableData(_tableData);
	}, [documents]);

	const getFieldName = useCallback((field: string) => {
		switch (field) {
			case 'title':
				return 'documentName';
			case 'size':
				return 'documentSize';
			case 'date':
				return 'documentDate';
			default:
				return '';
		}
	}, []);

	const handleSortModelChange = useCallback(
		(sortModel: GridSortModel) => {
			const [sortItem] = sortModel;
			if (sortItem) {
				setSortBy(getFieldName(sortItem.field));
				setSortDirection(sortItem.sort === 'asc' ? 'ASC' : 'DESC');
			} else {
				setSortBy('');
				setSortDirection('ASC');
			}
		},
		[getFieldName, setSortBy, setSortDirection]
	);

	return (
		<Box sx={{ background: '#E8E8F0', height: '100%', width: 'auto', padding: '10px' }}>
			<DataGrid
				rows={tableData}
				columns={COLUMNS}
				checkboxSelection
				selectionModel={selectedIds}
				onSelectionModelChange={(selectionModel) => setSelectedIds(selectionModel)}
				keepNonExistentRowsSelected
				density="comfortable"
				disableColumnMenu
				hideFooterSelectedRowCount
				pageSize={pageSize}
				loading={loading}
				rowHeight={40}
				error={error}
				sortingMode="server"
				sortingOrder={['desc', 'asc', null]}
				onSortModelChange={handleSortModelChange}
				components={{
					Pagination: () => (
						<CustomPagination
							page={page}
							setPage={setPage}
							pageCount={pageCount}
							pageSize={pageSize}
							setPageSize={setPageSize}
							totalDocs={totalDocs}
							selectedIds={selectedIds}
						/>
					),
					NoRowsOverlay: () => (
						<Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
							<Typography>No documents</Typography>
						</Box>
					),
				}}
				sx={{
					fontSize: 12,
				}}
			/>
			<DownloadDialog
				open={dialogOpen}
				setOpen={setDialogOpen}
				selectedIds={selectedIds}
				setSelectedIds={setSelectedIds}
				selectedFilters={selectedFilters}
				sort={sortBy}
				order={sortDirection}
			/>
		</Box>
	);
};

type CustomPaginationProps = {
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	pageCount: number;
	pageSize: number;
	setPageSize: React.Dispatch<React.SetStateAction<number>>;
	totalDocs: number;
	selectedIds: Array<GridRowId>;
};

const CustomPagination: React.FC<CustomPaginationProps> = ({
	page,
	setPage,
	pageCount,
	pageSize,
	setPageSize,
	totalDocs,
	selectedIds = [],
}) => {
	const [downloadSize, setDownloadSize] = useState(0);

	const qClient = useQueryClient();

	useEffect(() => {
		let cachedDocuments = (
			qClient
				.getQueryCache()
				.getAll()
				.filter((q) => q.queryKey.includes('documents'))
				.map((q) => q.state.data)
				.filter((_) => !!_) as Array<FetchDocumentsApiResponse>
		).flatMap((_) => _.data);

		const uniqueDocuments = cachedDocuments.filter(
			(doc, index, self) => index === self.findIndex((t) => t.documentId === doc.documentId)
		);

		const totalSize = uniqueDocuments
			.filter((_) => selectedIds.includes(_.documentId))
			.reduce((acc, doc) => acc + doc.documentSize, 0);
		setDownloadSize(totalSize);
	}, [selectedIds, qClient]);

	const formatDownloadSize = (size: number) => {
		return bytes.format(size, { decimalPlaces: 2, fixedDecimals: false, unitSeparator: ' ' });
	};

	return (
		<Grid container alignItems="center" justifyContent="space-between" mt={2}>
			<Grid item display="flex" flexDirection="row">
				<Typography>
					<Typography mr={1} fontWeight="bold" component={'span'}>
						{totalDocs}
					</Typography>
					Documents found
				</Typography>
				{!!selectedIds.length && downloadSize !== 0 && (
					<>
						<Typography style={{ margin: '0 10px' }}> - </Typography>
						<Typography>
							<Typography mr={1} fontWeight="bold" component={'span'}>
								{selectedIds.length}
							</Typography>
							documents selected ({formatDownloadSize(downloadSize)} in total)
						</Typography>
					</>
				)}
			</Grid>
			<Grid item>
				<Grid container alignItems="center" justifyContent="center">
					<Grid item>
						<Pagination
							color="secondary"
							sx={{ alignContent: 'center' }}
							page={page}
							count={pageCount}
							renderItem={(props: any) => <PaginationItem {...props} disableRipple />}
							onChange={(_e, value) => setPage(value)}
						/>
					</Grid>
					<Grid item>
						<Select
							labelId="page-size"
							value={pageSize}
							label="items"
							sx={{ height: 35 }}
							size="small"
							onChange={(e) => setPageSize(Number(e.target.value))}
						>
							<MenuItem value={10}>
								<Typography variant="button">10</Typography>
							</MenuItem>
							<MenuItem value={20}>
								<Typography variant="button">20</Typography>
							</MenuItem>
							<MenuItem value={50}>
								<Typography variant="button">50</Typography>
							</MenuItem>
							<MenuItem value={100}>
								<Typography variant="button">100</Typography>
							</MenuItem>
						</Select>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default GameListTable;
