import axios from 'axios';
import { API_BASE_URL_PRODUCITON } from '../config';

const axiosInstance = axios.create({
	baseURL: API_BASE_URL_PRODUCITON,
	headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
