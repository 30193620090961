import { Box } from '@mui/material';
import { headerCase } from 'header-case';
import { decode } from 'html-entities';
import { useCallback, useEffect, useState } from 'react';
import type { Filters, UIFilterType } from '../../types';
import FilterAccordion from './FilterAccordion';

const NO_TOGGLE_BUTTON = ['marketing', 'compliance'];
const NOT_SERCHABLE = ['marketing', 'compliance'];
const SORT_ORDER = ['marketing', 'compliance', 'gameStudio', 'gameName', 'jurisdiction', 'language', 'gameType'];

type Props = {
	smallScreen?: boolean;
	filters: Filters;
	initialOpenFilters?: Array<string>;
	selectedFilters: Filters;
	setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
};

const FiltersSidebar: React.FC<Props> = ({
	smallScreen,
	filters,
	initialOpenFilters = [],
	selectedFilters,
	setSelectedFilters,
}) => {
	const [uiFilters, setUiFilters] = useState<Array<UIFilterType>>([]);

	useEffect(() => {
		if (
			selectedFilters.jurisdiction?.length &&
			(selectedFilters.marketing?.length || !selectedFilters.compliance.length)
		) {
			setSelectedFilters((prev) => ({
				...prev,
				jurisdiction: [],
			}));
		}

		if (selectedFilters.language?.length && !selectedFilters.marketing?.includes('Game Rules')) {
			setSelectedFilters((prev) => ({
				...prev,
				language: [],
			}));
		}

		if (selectedFilters.marketing?.length && selectedFilters.compliance?.length) {
			setSelectedFilters((prev) => ({
				...prev,
				compliance: [],
			}));
		}

		if (selectedFilters.compliance?.length && selectedFilters.marketing?.length) {
			setSelectedFilters((prev) => ({
				...prev,
				marketing: [],
			}));
		}
	}, [selectedFilters, setSelectedFilters]);

	const isVisible = useCallback(
		(filter: string) => {
			if (filter === 'jurisdiction') {
				if (selectedFilters.compliance && selectedFilters.compliance.length > 0) return true;
				return false;
			} else if (filter === 'language') {
				if (selectedFilters.marketing?.includes('Game Rules')) return true;
				return false;
			}
			return true;
		},
		[selectedFilters]
	);

	const isLocked = useCallback(
		(filter: string) => {
			if (filter === 'jurisdiction' && selectedFilters.marketing?.length) {
				return true;
			}

			if (filter === 'compliance' && selectedFilters.marketing?.length) {
				return true;
			}

			if (filter === 'marketing' && selectedFilters.compliance?.length) {
				return true;
			}
			return false;
		},
		[selectedFilters]
	);

	useEffect(() => {
		const _uiFilters = Object.entries(filters).map(([key, values]) => ({
			name: key,
			label: headerCase(key, { delimiter: ' ' }),
			openByDefault: (initialOpenFilters.length ? initialOpenFilters : ['marketing', 'compliance']).includes(key),
			showToggleButton: !NO_TOGGLE_BUTTON.includes(key),
			searchable: !NOT_SERCHABLE.includes(key),
			items: values.map((item) => {
				const _decodedItem = decode(item);
				return {
					id: _decodedItem,
					name: _decodedItem,
					label: _decodedItem,
				};
			}),
			sortIndex: SORT_ORDER.indexOf(key),
			visible: isVisible(key),
			locked: isLocked(key),
		}));

		setUiFilters(_uiFilters);
	}, [filters, isVisible, isLocked, initialOpenFilters]);

	return (
		<Box
			width="100%"
			sx={{
				width: '100%',
				overflowY: 'auto',
				height: smallScreen ? '100vh' : 'calc(100vh - 95px)',
			}}
		>
			<FilterAccordion
				title="Clear All Filters"
				name="clearAll"
				selectedFilters={selectedFilters}
				setSelectedFilters={setSelectedFilters}
				items={[]}
				clearAll
			/>
			{uiFilters
				.filter((filter) => filter.visible)
				.map((filter) => {
					if (filter.locked) {
						return (
							<FilterAccordion
								key={filter.name}
								title={filter.label}
								name={filter.name}
								selectedFilters={selectedFilters}
								setSelectedFilters={setSelectedFilters}
								items={filter.items}
								searchable={filter.searchable}
								showToggleButton={filter.showToggleButton}
								openByDefault={filter.openByDefault}
								locked
							/>
						);
					} else {
						return (
							<FilterAccordion
								key={filter.name}
								title={filter.label}
								name={filter.name}
								selectedFilters={selectedFilters}
								setSelectedFilters={setSelectedFilters}
								items={filter.items}
								searchable={filter.searchable}
								showToggleButton={filter.showToggleButton}
								openByDefault={filter.openByDefault}
							/>
						);
					}
				})}
		</Box>
	);
};

export default FiltersSidebar;
