import { DOCUMENTS_ENDPOINT } from '../config';
import { FetchDocumentsApiResponse, Filters } from '../types';
import { axios } from '../utils';

export const fetchDocuments = (
	queryKey: Array<any>,
	token: string,
	signal?: AbortSignal
): Promise<FetchDocumentsApiResponse> => {
	const [, page, limit, selectedFilters, sort, order, search, collection] = queryKey as [
		string,
		number,
		number,
		Filters,
		string,
		'ASC' | 'DESC',
		string?,
		string?
	];

	const filters = Object.keys(selectedFilters).reduce(
		(prev, curr) => ({ ...prev, [curr]: selectedFilters[curr].join(',') ?? false }),
		{}
	);

	let url = DOCUMENTS_ENDPOINT;
	let queryParams: URLSearchParams;

	queryParams = new URLSearchParams({ page: `${page}`, limit: `${limit}`, ...filters, sort, order });

	if (search) queryParams.append('search', search);
	if (collection) queryParams.append('collection', collection);

	url += `?${queryParams.toString()}`;

	return axios.get(url, { signal, headers: { Authorization: `Bearer ${token}` } }).then((res) => res.data);
};
