import { Theme } from '@mui/material';

const DataGrid = (theme: Theme) => {
	return {
		MuiDataGrid: {
			styleOverrides: {
				root: {
					'& .MuiDataGrid-iconSeparator': {
						display: 'none',
					},
					'& .MuiDataGrid-columnHeader:focus': {
						outline: 'none !important',
					},
					'& .MuiDataGrid-cell:focus': {
						outline: 'none !important',
					},
					'& .MuiDataGrid-columnHeader:focus-within': {
						outline: 'none !important',
					},
					'& .MuiDataGrid-cell:focus-within': {
						outline: 'none !important',
					},
					'& .MuiDataGrid-footerContainer': {
						border: 'none',
						display: 'flex',
						justifyContent: 'center',
					},
					background: 'white',
					boxShadow: '0px 0px 0px #5A71D01B',
					borderRadius: '14px',
					padding: '30px',
				},
			},
		},
	};
};

export default DataGrid;
