import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridRowId } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import { decode } from 'html-entities';
import Zip from 'jszip';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { DOCUMENTS_ENDPOINT } from '../config';
import SearchContext from '../context/SearchContext';
import { FetchDocumentsApiResponse, Filters } from '../types';
import { downloadFile, getBlobFromURL, isValidUrl, NameAndBlob, wait } from '../utils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

type UrlWithSize = [string, number, string];

interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

declare const window: any;

const BootstrapDialogTitle: React.FC<DialogTitleProps> = (props) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

type DownloadDialogProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onCancel?: () => void;
	setSelectedIds?: React.Dispatch<React.SetStateAction<Array<GridRowId>>>;
	selectedIds?: Array<GridRowId>;
	selectedFilters: Filters;
	sort: string;
	order: string;
};

const DOWNLOAD_CHUNK_SIZE = 1024 * 1024 * 1024 * 2; // 2GB
const PAGE_SIZE_LIMIT = 50; // 50 documents per page
const FETCH_DELAY = 1000 * 3; // 3 seconds

const DownloadDialog: React.FC<DownloadDialogProps> = ({
	open,
	setOpen,
	onCancel,
	selectedIds = [],
	selectedFilters,
	setSelectedIds,
	sort,
	order,
}) => {
	const { state } = useContext(SearchContext);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<undefined | string>();
	const abortController = useRef(new AbortController());

	const qClient = useQueryClient();

	const downloadChunk = async (urlsWithSizes: Array<UrlWithSize>, fileName: string): Promise<void> =>
		new Promise(async (resolve, reject) => {
			try {
				const fileBlobsWithNames: Array<NameAndBlob> = [];

				for (const i of urlsWithSizes) {
					const [url] = i;
					if (!isValidUrl(url)) continue;
					const blobWithName = await getBlobFromURL(
						url.split('/').pop()!,
						url,
						abortController.current.signal
					);
					if (blobWithName) fileBlobsWithNames.push(blobWithName);
				}

				const zip = new Zip();
				fileBlobsWithNames.map((i) => zip.file(...i));
				const blob = await zip.generateAsync({ type: 'blob' });

				const _url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = _url;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(_url);
				a.parentNode?.removeChild(a);
				resolve();
			} catch (err) {
				reject(err);
			}
		});

	const generateChunks = (urlsWithSizes: Array<UrlWithSize>): Array<Array<UrlWithSize>> => {
		const chunks: Array<Array<UrlWithSize>> = [];
		let currentChunkSize = 0;
		let currentChunk: Array<UrlWithSize> = [];
		for (const i of urlsWithSizes) {
			if (currentChunkSize + i[1] > DOWNLOAD_CHUNK_SIZE) {
				chunks.push(currentChunk);
				currentChunk = [];
				currentChunkSize = 0;
			}
			currentChunk.push(i);
			currentChunkSize += i[1];
		}
		chunks.push(currentChunk);
		return chunks;
	};

	const handleDownload = async () => {
		const downloadingSelectedDocuments = selectedIds.length > 0;

		let urlsWithSizes: Array<UrlWithSize> = [];
		if (downloadingSelectedDocuments) {
			let cachedDocuments = (
				qClient
					.getQueryCache()
					.getAll()
					.filter((q) => q.queryKey.includes('documents'))
					.map((q) => q.state.data)
					.filter((_) => !!_) as Array<FetchDocumentsApiResponse>
			).flatMap((_) => _.data);

			selectedIds.forEach((id) => {
				const doc = cachedDocuments.find((_) => _.documentId === id);
				if (doc) {
					if (!isValidUrl(doc.fileUrl)) console.log(`missing fileUrl: ${decode(doc.documentName)}`);
					urlsWithSizes.push([doc.fileUrl, doc.documentSize, decode(doc.documentName)]);
				}
			});
		} else {
			const filters = Object.keys(selectedFilters).reduce(
				(prev, curr) => ({ ...prev, [curr]: selectedFilters[curr].join(',') ?? false }),
				{}
			);

			const url = DOCUMENTS_ENDPOINT;
			let queryParams: URLSearchParams;
			let page = 1;
			queryParams = new URLSearchParams({
				page: `${page}`,
				limit: `${PAGE_SIZE_LIMIT}`,
				...filters,
				sort,
				order,
				search: state.searchQuery,
			});

			for (;;) {
				if (abortController.current.signal.aborted) break;
				try {
					const res = await fetch(`${url}?${queryParams.toString()}`, {
						headers: { 'Content-Type': 'application/json' },
						signal: abortController.current.signal,
					});

					const _data = (await res.json()) as FetchDocumentsApiResponse;

					_data.data
						.map((_) => {
							if (!isValidUrl(_.fileUrl)) console.log(`missing fileUrl: ${decode(_.documentName)}`);
							return [_.fileUrl, _.documentSize, decode(_.documentName)] as UrlWithSize;
						})
						.forEach((i) => {
							urlsWithSizes.push(i);
						});

					if (_data.data.length < PAGE_SIZE_LIMIT) break;

					page++;
					queryParams = new URLSearchParams({
						page: `${page}`,
						limit: `${PAGE_SIZE_LIMIT}`,
						...filters,
						sort,
						order,
						search: state.searchQuery,
					});
					await wait(FETCH_DELAY);
				} catch (err: any) {
					setError(err?.message ?? 'An unknown error occurred');
					break;
				}
			}
		}

		if (urlsWithSizes.length === 1) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const [url, _size, title] = urlsWithSizes[0];
			const onFinish = (err?: string) => {
				setError(err);
				setSelectedIds?.([]);
				setLoading(false);
			};
			downloadFile(url, title, onFinish);
			return;
		}

		const findDuplicates = (arr: Array<string>) => arr.filter((item, index) => arr.indexOf(item) !== index);

		const duplicatesUrls = findDuplicates(urlsWithSizes.map((i) => i[0]));

		console.log(`duplicate Urls`);
		// get duplicate count
		const duplicatesCount = duplicatesUrls.reduce((prev, curr) => {
			const count = urlsWithSizes.filter((i) => i[0] === curr).length;
			return { ...prev, [curr]: count };
		}, {} as Record<string, number>);
		console.log(duplicatesCount);

		const uniqueUrlsWithSizes = urlsWithSizes.filter(
			(i, index, self) => self.findIndex((j) => j[0] === i[0]) === index
		);

		console.log(`total unique urls: ${uniqueUrlsWithSizes.length}`);

		const generatedChunks = generateChunks(urlsWithSizes.filter((_) => isValidUrl(_[0])));

		if (generatedChunks.length > 1) {
			for (const [i, chunk] of generatedChunks.entries()) {
				await downloadChunk(chunk, `client-area-files-${i + 1}.zip`);
			}
		} else {
			await downloadChunk(urlsWithSizes, 'client-area-files.zip');
		}

		if (window.dataLayer) {
			if (uniqueUrlsWithSizes.length > 10) {
				window.dataLayer.push({
					event: 'document_download',
					eventProps: {
						download_number_of_files: uniqueUrlsWithSizes.length,
						download_filter: window.location.href,
						download_document_name: 'Multiple Documents',
					},
				});
			} else {
				for (let i = 0; i < uniqueUrlsWithSizes.length; i++) {
					window.dataLayer.push({
						event: 'document_download',
						eventProps: {
							download_number_of_files: 1,
							download_filter: window.location.href,
							download_document_name: uniqueUrlsWithSizes[i][2],
						},
					});
				}
			}
		}

		setError('');
		if (selectedIds.length === 1) setSelectedIds?.([]);
		setLoading(false);
	};

	useEffect(() => {
		if (open) {
			abortController.current = new AbortController();
			setLoading(true);
			setError('');
			handleDownload();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const handleClose = useCallback(() => {
		if (loading) {
			// eslint-disable-next-line no-restricted-globals
			const yes = confirm('Are you sure you want to cancel the download?');
			if (yes) {
				abortController.current.abort();
				onCancel?.();
				setOpen(false);
			}
		} else {
			setOpen(false);
		}
	}, [loading, setOpen, onCancel]);

	return (
		<BootstrapDialog
			fullWidth
			maxWidth="sm"
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
		>
			<BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
				Preparing download
			</BootstrapDialogTitle>
			<DialogContent dividers>
				{loading ? (
					<>
						<Typography style={{ padding: '8px' }}>
							Your download has started. Please do not close the window until it's finished.
						</Typography>
						<LinearProgress />
					</>
				) : error ? (
					<Typography style={{ padding: '8px' }}>{error}</Typography>
				) : (
					<Typography style={{ padding: '8px' }}>
						Your download is now ready. You can close this popup.
					</Typography>
				)}
			</DialogContent>
		</BootstrapDialog>
	);
};

export default DownloadDialog;
