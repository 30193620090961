import { FILTERS_ENDPOINT } from '../config';
import type { Filters } from '../types';
import { axios } from '../utils';

export const fetchFilters = (token: string, signal?: AbortSignal): Promise<Filters> =>
	axios
		.get(FILTERS_ENDPOINT, {
			signal,
			headers: { Authorization: `Bearer ${token}` },
		})
		.then((res) => res.data);
