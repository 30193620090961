export type NameAndBlob = [string, Blob];

export const getBlobFromURL = async (
	filename: string,
	url: string,
	signal: AbortSignal
): Promise<null | NameAndBlob> => {
	try {
		const response = await fetch(url, { signal });
		const blob = await response.blob();
		return [filename, blob];
	} catch (err) {
		if (err instanceof Error) {
			err.name === 'AbortError' && console.log(err.message);
		}
		return null;
	}
};
