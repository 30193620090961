import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Banner } from '../../types';

declare const window: any;

type Props = {
	banners: Array<Banner>;
};

const BottomLongCarousel: React.FC<Props> = ({ banners }) => {
	const [swipe, setSwipe] = useState<any>();
	const buttonStyles = {
		background: 'white',
		borderRadius: '20%',
		boxShadow: '0px 0px 10px rgba(0,0,0,0.4)',
		'&:hover': {
			background: 'rgba(255,255,255,0.9)',
			boxShadow: '0px 0px 10px rgba(0,0,0,0.6)',
		},
	};

	const bannerClick = (link: string) => {
		if (link) {
			if (window.dataLayer) {
				window.dataLayer.push({
					event: 'homepage_item_click',
					eventProps: {
						homepage_clicked_section: 'Banner Carousel'
					}
				});
			}
			window.open(link, '_blank');
		}
	}

	return (
		<Box width="100%" display="flex" alignItems="center">
			<Box width="5%" display="flex" justifyContent="center">
				<IconButton onClick={() => swipe?.slidePrev()} sx={buttonStyles}>
					<KeyboardArrowLeft />
				</IconButton>
			</Box>
			<Box width="90%">
				<Swiper
					onBeforeInit={(swipper) => setSwipe(swipper)}
					modules={[Navigation, Autoplay]}
					speed={1200}
					autoplay={{
						delay: 6000,
						disableOnInteraction: false,
						pauseOnMouseEnter: true,
					}}
					loop={banners.length > 1}
					className="mySwiper"
				>
					{banners?.map(({ image, link }, index) => (
						<SwiperSlide key={index}>
							<Box
								sx={{
									width: '100%',
									height: '300px',
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'contain',
									backgroundImage: `url(${image})`,
									backgroundPosition: 'center',
									cursor: link ? 'pointer' : 'default',
								}}
								onClick={() => bannerClick(link)}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</Box>
			<Box width="5%" display="flex" justifyContent="center">
				<IconButton sx={buttonStyles} onClick={() => swipe?.slideNext()}>
					<KeyboardArrowRight />
				</IconButton>
			</Box>
		</Box>
	);
};

export default BottomLongCarousel;
