import { Theme } from '@mui/material';
import Button from './Button';
import { Checkbox } from './Checkbox';
import DataGrid from './DataGrid';
import TextField from './TextField';

const ComponentsOverrides = (theme: Theme) =>
	Object.assign(Button(theme), DataGrid(theme), Checkbox(theme), TextField(theme));

export default ComponentsOverrides;
