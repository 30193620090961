import { Theme } from '@mui/material';

const Button = (theme: Theme) => {
	return {
		MuiButton: {
			styleOverrides: {
				sizeLarge: {
					height: 60,
				},
				outlined: {
					padding: '6px 14px',
					color: theme.palette.primary.main,
					backgroundColor: 'transparent',
					borderRadius: '11px',
					boxShadow: 'none',
					fontWeight: 'bold',
					fontSize: 11,
					border: `2px solid ${theme.palette.primary.main}`,
					svg: {
						fontSize: 18
					},
					'&:hover': {
						backgroundColor: '#61CE75',
						color: '#fff',
						border: `2px solid ${'#61CE75'}`,
						boxShadow: 'none',
					}
				},
				contained: {
					padding: '5px 15px',
					color: '#FFFFFF',
					background: '#61CE75 0% 0% no-repeat padding-box',
					borderRadius: '11px',
					boxShadow: 'none',
					fontWeight: 'bold',
					border: `2px solid ${'#61CE75'}`,
					'&:hover': {
						backgroundColor: '#61CE75',
						color: '#fff',
						boxShadow: 'none',
					},
				},
			},
		},
	};
};

export default Button;
