import { AUTH_ENDPOINT, TOKEN_VALIDATE_ENDPOINT, WHOAMI_ENDPOINT } from '../config';
import { axios } from '../utils';

export const authenicate = async (username: string, password: string): Promise<{ token: string, email: string, companyName: string } | boolean> => {
	const body = JSON.stringify({ username, password });
	const res = await axios.post(AUTH_ENDPOINT, body);
	let result;

	if (res.data.token) {
		result = {
			token: res.data.token,
			email: res.data.user_email,
			companyName: res.data.user_company_name,
		}
		return result;
	} else {
		return false;
	}
};

export const whoami = async (token: string): Promise<any> => {
	const res = await axios.post(WHOAMI_ENDPOINT, {
		headers: { Authorization: `Bearer ${token}` },
	});
	return res.data;
};

export const verifyToken = async (token: string): Promise<any> => {
	const res = await axios.post(TOKEN_VALIDATE_ENDPOINT, null, { headers: { Authorization: `Bearer ${token}` } });
	return res.data.code === 'jwt_auth_valid_token';
};
