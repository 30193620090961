import React, { createContext, useReducer } from 'react';

const initialState = {
	searchQuery: '',
};

type State = typeof initialState;

type Action = {
	type: 'SEARCH' | 'RESET';
	payload?: Partial<State>;
};

const SearchContext = createContext({ state: initialState, dispatch: (action: Action) => {} });

const searchReducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'SEARCH':
			return {
				...state,
				searchQuery: action.payload?.searchQuery ?? '',
			};
		case 'RESET':
			return {
				...state,
				searchQuery: '',
			};
		default:
			return state;
	}
};

type Props = {
	children?: React.ReactNode;
};

export const SearchProvider: React.FC<Props> = ({ children }) => {
	const [state, dispatch] = useReducer(searchReducer, initialState);

	return <SearchContext.Provider value={{ state, dispatch }}>{children}</SearchContext.Provider>;
};

export default SearchContext;
