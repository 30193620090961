import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from '../components/PrivateRoutes';
import GameLibrary from '../pages/GameLibrary';
import Home from '../pages/Home';
import LoginPage from '../pages/Login';
import MasterGameList from '../pages/MasterGameList';
import Roadmap from '../pages/Roadmap';
import { PAGE_PATH } from './paths';

const Router = () => (
	<Routes>
		<Route element={<PrivateRoutes />}>
			<Route path="/" element={<Home />} />
			<Route path={PAGE_PATH.gameLibrary} element={<GameLibrary />} />
			<Route path={PAGE_PATH.masterGameList} element={<MasterGameList />} />
			<Route path={PAGE_PATH.roadmap} element={<Roadmap />} />
		</Route>
		<Route path={PAGE_PATH.login} element={<LoginPage />} />
	</Routes>
);

export default Router;
