import { Box, CircularProgress } from '@mui/material';
import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const PrivateRoutes: React.FC = () => {
	const { state } = useContext(AuthContext);

	if (state.loading)
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height="95vh" width="99vw">
				<CircularProgress />
			</Box>
		);

	return state.token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
