export const API_BASE_URL_STAGING = process.env.REACT_APP_API_BASE_STAGING || '';
export const API_BASE_URL_PRODUCITON = process.env.REACT_APP_API_BASE_PRODUCTION || '';

export const AUTH_ENDPOINT = `${API_BASE_URL_PRODUCITON}/wp-json/jwt-auth/v1/token`;
export const TOKEN_VALIDATE_ENDPOINT = `${API_BASE_URL_PRODUCITON}/wp-json/jwt-auth/v1/token/validate`;
export const WHOAMI_ENDPOINT = `${API_BASE_URL_PRODUCITON}/wp-json/wp/v2/users/me`;

export const HOMEPAGE_ENDPOINT = `${API_BASE_URL_PRODUCITON}/wp-json/ppca/v1/homepage`;
export const FILTERS_ENDPOINT = `${API_BASE_URL_PRODUCITON}/wp-json/ppca/v1/filters`;
export const DOCUMENTS_ENDPOINT = `${API_BASE_URL_PRODUCITON}/wp-json/ppca/v1/documents`;
export const DOWNLOAD_ENDPOINT = `${API_BASE_URL_PRODUCITON}/wp-json/ppca/v1/download`;
