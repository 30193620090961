import { Box, Checkbox, Grid, Typography } from '@mui/material';

type Props = {
	name: string;
	label: string;
	checked?: boolean;
	handleItemSelection: (item: string) => void;
};

const AccordionItem: React.FC<Props> = ({ label, name, checked, handleItemSelection }) => {
	return (
		<Box>
			<label onChange={() => handleItemSelection(name)}>
				<Grid sx={{ cursor: 'pointer' }} container alignItems="center">
					<Grid item xs={2}>
						<Checkbox checked={checked} color="primary" />
					</Grid>
					<Grid item xs={10}>
						<Typography
							className="noselect"
							color={checked ? 'primary' : 'secondary'}
							fontWeight="500"
							variant="body1"
						>
							{label}
						</Typography>
					</Grid>
				</Grid>
			</label>
		</Box>
	);
};

export default AccordionItem;
