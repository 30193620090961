import { Theme } from '@mui/material';

const TextField = (theme: Theme) => {
	return {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					fontSize: 16,
					background: 'white',
					borderRadius: '11px',
					boxShadow: 'none',
					border: 'none',
					'&:hover': {
						border: `none`,
						boxShadow: 'none',
					},
				},
			},
		},
	};
};

export default TextField;
