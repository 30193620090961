import { HOMEPAGE_ENDPOINT } from '../config';
import type { HomepageAPIResponse } from '../types';
import { axios } from '../utils';

export const fetchHomepage = (token: string, signal?: AbortSignal): Promise<HomepageAPIResponse> =>
	axios
		.get(HOMEPAGE_ENDPOINT, {
			signal,
			headers: { Authorization: `Bearer ${token}` },
		})
		.then((res) => res.data);
