import CloseIcon from '@mui/icons-material/Close';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {
	Box,
	CircularProgress,
	Grid,
	IconButton,
	Stack,
	SwipeableDrawer,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { fetchDocuments, fetchFilters } from '../api';
import FiltersSidebar from '../components/GameLibrary/FiltersSidebar';
import GameListTable from '../components/GameLibrary/GameListTable';
import AuthContext from '../context/AuthContext';
import SearchContext from '../context/SearchContext';
import { Filters } from '../types';

const GameLibrary: React.FC = () => {
	const isMobile = useMediaQuery('(max-width:1199px)');
	const [drawerOpen, setDrawerOpen] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();

	const { state: authState } = useContext(AuthContext);
	const { state, dispatch } = useContext(SearchContext);

	const [initialFilters, setInitialFilters] = useState<Filters>({});
	const [selectedFilters, setSelectedFilters] = useState<Filters>({});

	const { status: filtersFetchStatus, data: filters } = useQuery(
		['fiters'],
		({ signal }) => fetchFilters(authState.token, signal),
		{ enabled: !!authState.token }
	);

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const [sortBy, setSortBy] = useState('');
	const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');

	const [ready, setReady] = useState(false);

	const {
		status: fetchDocumentsStatus,
		data: fetchDocumentsResponse,
		error: documentsFetchError,
		isRefetching: documentsIsRefetching,
	} = useQuery(
		['documents', page, pageSize, selectedFilters, sortBy, sortDirection, state.searchQuery],
		({ queryKey, signal }) => fetchDocuments(queryKey, authState.token, signal),
		{ enabled: !!(authState && filters) }
	);

	const pageCount = Math.ceil((fetchDocumentsResponse?.count ?? 1) / pageSize);

	// set fetched filters as initial filters
	useEffect(() => {
		if (filtersFetchStatus === 'success' && filters) {
			const _initialParams: Filters = {};
			searchParams.forEach((val, key) => {
				if (key === 'search') return;
				const _val = val.split(',');
				if (_val) _initialParams[key] = _val ?? [];
			});

			const search = searchParams.get('search');
			if (search) dispatch({ type: 'SEARCH', payload: { searchQuery: search } });

			setInitialFilters(() => ({
				...Object.keys(filters).reduce<Filters>((acc, key) => ({ ...acc, [key]: [] }), {}),
				..._initialParams,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersFetchStatus, filters]);

	// set initial filters as selected filters
	useEffect(() => {
		if (Object.keys(initialFilters).length) {
			setSelectedFilters(initialFilters);
		}
	}, [initialFilters]);

	const initialFilterNames = useMemo(
		() =>
			Object.entries(initialFilters)
				.filter(([key, val]) => val.length)
				.map(([key]) => key),
		[initialFilters]
	);

	// update search params from the selected filters
	useEffect(() => {
		let _searchParams = new URLSearchParams();
		if (Object.keys(selectedFilters).length) {
			Object.entries(selectedFilters)
				.filter(([, val]) => val.length)
				.forEach(([key, value]) => _searchParams.set(key, value.join(',')));
			if (state.searchQuery) _searchParams.set('search', state.searchQuery);
			else _searchParams.delete('search');
			setSearchParams(_searchParams);
		}
	}, [state.searchQuery, selectedFilters, setSearchParams]);

	useEffect(() => {
		setPage(1);
	}, [pageSize, state.searchQuery, selectedFilters, sortBy, sortDirection]);

	useEffect(() => {
		if (!ready) {
			const _initialParams: Filters = {};

			searchParams.forEach((val, key) => {
				if (key === 'search') return;
				const _val = val.split(',');
				if (_val) _initialParams[key] = _val ?? [];
			});

			if (Object.values(initialFilters).flat().length === Object.values(_initialParams).flat().length) {
				setReady(true);
			}
		}
	}, [ready, initialFilters, searchParams]);

	if (filtersFetchStatus !== 'success' || !ready) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height={'80vh'} width="100%">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Box>
			<Grid width="100%" height={'100vh'} paddingTop="94px" container>
				{isMobile ? (
					<>
						<SwipeableDrawer
							anchor={'left'}
							open={drawerOpen}
							onClose={() => setDrawerOpen(false)}
							onOpen={() => setDrawerOpen(true)}
						>
							<Stack p={2} direction="row" justifyContent="space-between" alignItems="center">
								<Typography variant="h5">Filters</Typography>
								<IconButton onClick={() => setDrawerOpen(false)}>
									<CloseIcon />
								</IconButton>
							</Stack>
							<FiltersSidebar
								filters={filters!}
								initialOpenFilters={initialFilterNames}
								selectedFilters={selectedFilters}
								setSelectedFilters={setSelectedFilters}
								smallScreen
							/>
						</SwipeableDrawer>
						<Box position="relative">
							<Box
								sx={{ background: 'white', border: '1px solid gray' }}
								p="3"
								borderRadius={2}
								position="absolute"
								zIndex="100"
							>
								<IconButton sx={{ transform: 'scaleX(-1)' }} onClick={() => setDrawerOpen(true)}>
									<MenuOpenIcon />
								</IconButton>
							</Box>
						</Box>
					</>
				) : (
					<Grid xl={2} lg={2.5} item>
						<FiltersSidebar
							filters={filters!}
							initialOpenFilters={initialFilterNames}
							selectedFilters={selectedFilters}
							setSelectedFilters={setSelectedFilters}
						/>
					</Grid>
				)}
				<Grid xs={12} xl={10} lg={9.5} item>
					<GameListTable
						loading={fetchDocumentsStatus === 'loading' || documentsIsRefetching}
						error={fetchDocumentsStatus === 'error' ? documentsFetchError : undefined}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						pageCount={pageCount}
						sortBy={sortBy}
						setSortBy={setSortBy}
						sortDirection={sortDirection}
						setSortDirection={setSortDirection}
						selectedFilters={selectedFilters}
						documents={fetchDocumentsResponse?.data ?? []}
						totalDocs={fetchDocumentsResponse?.count ?? 0}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default GameLibrary;
