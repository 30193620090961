import { decode } from 'html-entities';

declare const window: any;

export const downloadFile = (fileUrl: string, title: string, onFinish?: (err?: string) => void) => {

	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'document_download',
			eventProps: {
				download_number_of_files: 1,
				download_filter: window.location.href,
				download_document_name: decode(title)
			}
		});
	}

	fetch(fileUrl)
		.then((res) => res.blob())
		.then((b) => {
			const fileName = (fileUrl ?? '').split('/').pop();

			if (!fileName) throw new Error(`Invalid file name: ${fileName}`);

			const _url = window.URL.createObjectURL(b);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = _url;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(_url);
			a.parentNode?.removeChild(a);
			onFinish?.();
		})
		.catch((err) => {
			onFinish?.(String(err));
		}
	);
}
