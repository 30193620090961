import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LockIcon from '@mui/icons-material/Lock';
import SearchIcon from '@mui/icons-material/Search';
import {
	Box,
	Collapse,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { fetchFilters } from '../../api';
import AuthContext from '../../context/AuthContext';
import { Filters, UIFilterItemType } from '../../types';
import AccordionItem from '../AccordionItem';

type FilterAccordionProps = {
	title: string;
	name: string;
	items: Array<UIFilterItemType>;
	selectedFilters: Filters;
	setSelectedFilters: React.Dispatch<React.SetStateAction<Filters>>;
	showToggleButton?: boolean;
	openByDefault?: boolean;
	searchable?: boolean;
	clearAll?: boolean;
	locked?: boolean;
};

const FilterAccordion: React.FC<FilterAccordionProps> = ({
	title,
	items,
	name,
	showToggleButton = false,
	openByDefault = false,
	searchable = false,
	selectedFilters,
	setSelectedFilters,
	clearAll = false,
	locked = false,
}) => {
	const { palette } = useTheme();
	const [open, setOpen] = useState(() => openByDefault);

	const [searchQuery, setSearchQuery] = useState('');

	const { state: authState } = useContext(AuthContext);

	const selectedFilterValues = useMemo(() => Object.values(selectedFilters).flat(), [selectedFilters]);

	const GREY_FILTERS = useMemo(() => ['Marketing', 'Compliance'], []);

	const filteredItems = useMemo(() => {
		const _selected = items.filter((item) => selectedFilterValues.includes(item.name));
		const _unselected = items.filter((item) => !selectedFilterValues.includes(item.name));
		return [
			..._selected,
			..._unselected.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase())),
		];
	}, [items, selectedFilterValues, searchQuery]);
	const { data: filters } = useQuery(['fiters'], ({ signal }) => fetchFilters(authState.token, signal), {
		enabled: !!authState.token,
	});

	const isAnyItemSelected = useMemo(
		() => (clearAll ? true : selectedFilters[name] && selectedFilters[name].length),
		[clearAll, name, selectedFilters]
	);

	const handleItemSelection = (item: string) => {
		setSearchQuery('');
		const selected = selectedFilters[name].includes(item);
		if (selected) {
			setSelectedFilters((prevState) => ({
				...prevState,
				[name]: prevState[name].filter((_item) => _item !== item),
			}));
		} else {
			setSelectedFilters((prev) => ({
				...prev,
				[name]: [...prev[name], item].sort((a, b) => a.localeCompare(b)),
			}));
		}
	};

	const handleSelectAll = () => {
		setSearchQuery('');
		setSelectedFilters((prev) => {
			const currentFilters = prev[name];
			if (currentFilters?.length === items.length) {
				return {
					...prev,
					[name]: [],
				};
			}
			return {
				...prev,
				[name]: items.map((item) => item.name).sort((a, b) => a.localeCompare(b)),
			};
		});
	};

	const toggleButton = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.stopPropagation();
		if (!locked) setOpen((prev) => !prev);
		else setOpen(false);
	};

	const eraseAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		setSearchQuery('');
		setSelectedFilters((selected: any) => {
			return {
				...selected,
				[name]: [],
			};
		});
	};

	useEffect(() => {
		if (locked) setOpen(false);
		else setOpen(openByDefault);
	}, [locked, openByDefault]);

	const clearAllFilters = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		setSearchQuery('');
		const _initialParams: Filters = {};
		setSelectedFilters(() => ({
			...Object.keys(filters || {}).reduce<Filters>((acc, key) => ({ ...acc, [key]: [] }), {}),
			..._initialParams,
		}));
	};

	const accordionToggleStyles = useMemo(
		() => ({
			'&:hover': {
				cursor: locked ? 'not-allowed' : clearAll ? '' : 'pointer',
			},
			background: clearAll
				? 'white'
				: isAnyItemSelected
				? palette.primary.main
				: GREY_FILTERS.includes(title)
				? '#E8E8F0'
				: 'white',
			borderBottom: '1px solid #E8E8F0',
			padding: '3px 20px',
		}),
		[locked, isAnyItemSelected, palette.primary.main, clearAll, title, GREY_FILTERS]
	);

	const accordionContainerStyles = useMemo(
		() => ({
			background: isAnyItemSelected ? '#EFF8FF' : showToggleButton ? 'white' : '#F6F6FB',
			padding: '10px',
			width: '100%',
			height: 'auto',
			maxHeight: '350px',
			borderBottom: '1px solid #E8E8F0',
			overflowY: 'auto',
		}),
		[showToggleButton, isAnyItemSelected]
	);

	const getTooltipTitle = useCallback(
		(title: string): string => {
			if (!locked) return '';
			if (title.toLowerCase() === 'marketing') return 'To unlock, please disable the Compliance filter';
			if (title.toLowerCase() === 'compliance') return 'To unlock, please disable the Marketing filter';
			return 'This filter is locked';
		},
		[locked]
	);

	return (
		<Box sx={{ width: '100%' }}>
			<Tooltip title={getTooltipTitle(title)} placement="right" arrow>
				<Box sx={accordionToggleStyles} onClick={toggleButton}>
					<Stack direction={'row'} alignItems="center" justifyContent="space-between">
						<Typography
							className="noselect"
							color={!clearAll && isAnyItemSelected ? '#FFFFFF' : palette.secondary.main}
							variant="subtitle2"
							style={{ minHeight: 35, display: 'flex', alignItems: 'center' }}
						>
							{title}
						</Typography>
						<Stack direction={'row'} alignItems="center" spacing={1}>
							{locked ? (
								<IconButton disabled>
									<LockIcon />
								</IconButton>
							) : (
								<IconButton
									disabled={!isAnyItemSelected}
									onClick={clearAll ? clearAllFilters : eraseAll}
								>
									<img
										src={`${process.env.PUBLIC_URL}/images/erase-icon${
											!clearAll && isAnyItemSelected ? '-white' : ''
										}.svg`}
										alt="Erase Icon"
									/>
								</IconButton>
							)}
							{(!clearAll || showToggleButton) && (
								<IconButton
									sx={{
										color: !clearAll && isAnyItemSelected ? 'white' : '',
										cursor: locked ? 'not-allowed' : '',
									}}
									onClick={toggleButton}
									disabled={locked}
								>
									{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							)}
						</Stack>
					</Stack>
				</Box>
			</Tooltip>
			{!clearAll && (
				<Box>
					<Collapse in={open}>
						{searchable && (
							<Box
								sx={{
									padding: '20px 20px 0px 20px',
									background: isAnyItemSelected ? '#EFF8FF' : 'white',
								}}
							>
								<TextField
									fullWidth
									value={searchQuery}
									onChange={(e) => setSearchQuery(e.target.value)}
									type="text"
									placeholder="Search"
									size="small"
									sx={{ height: '100%', padding: 0 }}
									InputProps={{
										startAdornment: (
											<InputAdornment sx={{ padding: 0 }} position="start">
												<SearchIcon />
											</InputAdornment>
										),
									}}
								/>
							</Box>
						)}
						<Stack spacing={0.2} sx={accordionContainerStyles}>
							<AccordionItem
								label="All"
								name="all"
								checked={selectedFilters[name]?.length === items.length || false}
								handleItemSelection={handleSelectAll}
							/>
							{filteredItems.map((item) => (
								<AccordionItem
									key={item.id}
									label={item.label}
									name={item.name}
									handleItemSelection={handleItemSelection}
									checked={selectedFilters[name]?.includes(item.name) || false}
								/>
							))}
						</Stack>
					</Collapse>
				</Box>
			)}
		</Box>
	);
};

export default FilterAccordion;
